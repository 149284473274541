import { Button, Card, Col, Layout, Result, Row, Spin } from 'antd';
import { inject, observer } from 'mobx-react';
import * as qs from 'query-string';
import * as React from 'react';
import { history } from '../../common';
import { CheckoutStore } from '../checkout.store';
import { MolliePaymentStatus } from '../enums/mollie-payment-status.enum';

interface IProps {
    location: Location;
    checkoutStore: CheckoutStore;
}

@inject(({ checkoutStore }) => ({ checkoutStore }))
@observer
export class ResultPage extends React.Component<IProps> {
    public componentDidMount(): void {
        const { paymentId, token } = qs.parse(this.props.location.search);
        if (paymentId && token) {
            this.props.checkoutStore!.getInvoice(token as string);
            this.props.checkoutStore!.getPayment(token as string, paymentId as string);
        }
    }

    public render() {
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Row type="flex" justify="center">
                    <Col xs={22} sm={20} md={16} lg={12} xl={12}>
                        <div style={{ textAlign: 'center' }}>
                            <img width={100} src="/causamatics.png" alt="Causamatics" />
                        </div>
                        <Card>
                            {this.renderResult()}
                        </Card>
                    </Col>
                </Row>
            </Layout>
        );
    }

    private renderResult = () => {
        const { molliePayment, invoice } = this.props.checkoutStore!;
        if (molliePayment && invoice) {
            if (molliePayment.status === MolliePaymentStatus.OPEN) {
                return (
                    <Result icon={<Spin />} title="Uw betaling wordt verwerkt." />
                );
            } else if (molliePayment.status === MolliePaymentStatus.PAID) {
                return (
                    <Result
                        status="success"
                        title="U heeft succesvol betaald"
                        subTitle={`U heeft zonet € ${molliePayment.amount} betaald voor factuur ${invoice.id}.`}
                    />
                );
            } else {
                return (
                    <>
                        <Result
                            status="error"
                            title="De betaling is mislukt"
                            subTitle="Gelieve opnieuw te proberen."
                            extra={[
                                <Button key="retry" onClick={this.goToCheckout}>Probeer opnieuw</Button>,
                            ]}
                        />
                    </>
                );
            }
        }else{
            return null;
        }
    };

    private goToCheckout = () => {
        history.push(`/?token=${this.props.checkoutStore!.token}`);
    };
}
