import { notification } from 'antd';
import axios from 'axios';
import { observable } from 'mobx';
import { MolliePaymentStatus } from './enums/mollie-payment-status.enum';
import { IInvoice } from './models/invoice.model';
import { IMolliePayment } from './models/mollie-payment.model';

export class CheckoutStore {
    @observable public invoiceLoading: boolean = true;
    @observable public invoice: IInvoice | null = null;
    @observable public molliePaymentLoading: boolean = true;
    @observable public molliePayment: IMolliePayment | null = null;
    @observable public token: string = '';

    public async getInvoice(token: string): Promise<void> {
        try {
            this.invoiceLoading = true;
            this.token = token;
            const response = await axios.get('/causamatics-api/checkout', { params: { token } });
            console.log(response)
            this.invoice = response.data;
        } catch (err) {
            notification.error({
                description: `Er liep iets mis tijdens het ophalen van de factuur.`,
                message: 'Fout',
            });
        } finally {
            this.invoiceLoading = false;
        }
    }

    public async getPayment(token: string, paymentId: string): Promise<void> {
        try {
            this.molliePaymentLoading = true;
            this.token = token;

            const { data } = await axios.get('/causamatics-api/checkout/mollie-payment', {
                params: {
                    paymentId,
                    token,
                },
            });
            this.molliePayment = data;

            if (this.molliePayment && this.molliePayment.status === MolliePaymentStatus.OPEN) {
                setTimeout(() => {
                    this.getPayment(token, paymentId);
                }, 2000);
            }
        } catch (err) {
            notification.error({
                description: `Er liep iets mis tijdens het ophalen van de betaling.`,
                message: 'Fout',
            });
        } finally {
            this.molliePaymentLoading = false;
        }
    }

    public async pay(): Promise<void> {
        try {
            const response = await axios.post(`/causamatics-api/checkout/mollie-payment`, {
                amount: this.invoice!.amountDue,
                token: this.token,
            });
            window.location.href = response.data;
        } catch (e) {
            notification.error({
                description: `Er liep iets mis tijdens de betaling van ${this.invoice!.amountDue} voor factuur ${this.invoice!.id}.`,
                message: 'Fout',
            });
        }
    }

    public async payVAT(): Promise<void> {
        try {
            const response = await axios.post(`/causamatics-api/checkout/mollie-payment`, {
                amount: this.invoice!.amountTax,
                token: this.token,
            });
            window.location.href = response.data;
        } catch (e) {
            notification.success({
                description: `Er liep iets mis tijdens de betaling van ${this.invoice!.amountTax} voor factuur ${this.invoice!.id}.`,
                message: 'Fout',
            });
        }
    }
}
