import { Button, Card, Col, Descriptions, Layout, List, Result, Row, Typography } from 'antd';
import { inject, observer } from 'mobx-react';
import * as qs from 'query-string';
import * as React from 'react';
import { CheckoutStore } from '../checkout.store';
import { PaymentStatus } from '../enums/payment-status.enum';
import { IInvoiceItem } from '../models/invoice-item.model';

interface IProps {
    location: Location;
    checkoutStore: CheckoutStore;
}

@inject(({ checkoutStore }) => ({ checkoutStore }))
@observer
export class CheckoutPage extends React.Component<IProps> {
    public componentDidMount(): void {
        const { token } = qs.parse(this.props.location.search);
        if (token) {
            this.props.checkoutStore.getInvoice(token as string);
            
        }
    }

    public render() {
        const { invoice } = this.props.checkoutStore!;
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Row type="flex" justify="center">
                    <Col xs={22} sm={20} md={16} lg={12} xl={12}>
                        <div style={{ textAlign: 'center' }}>
                            <img width={100} src="/causamatics.png" alt="Causamatics" />
                        </div>
                        <Card
                            loading={this.props.checkoutStore!.invoiceLoading}
                            title={`Factuur ${invoice ? invoice.id : ''}`}
                        >
                            {(invoice && invoice!.paymentStatus !== PaymentStatus.PAID && invoice!.paymentStatus !== PaymentStatus.CLOSED && invoice!.amountDue) ? (
                                <>
                                    <div className="section">
                                        <Descriptions
                                            layout="vertical"
                                            size="small"
                                            colon={false}
                                            column={{
                                                md: 4,
                                                sm: 2,
                                                xs: 2,
                                            }}
                                        >
                                            <Descriptions.Item label="Klant">{invoice!.customer.billing.firstName} {invoice!.customer.billing.lastName}</Descriptions.Item>
                                            <Descriptions.Item label="Referentie">{invoice!.reference}</Descriptions.Item>
                                            <Descriptions.Item label="Factuur datum">{invoice!.invoiceDate}</Descriptions.Item>
                                            <Descriptions.Item label="Vervaldag">{invoice!.dueDate}</Descriptions.Item>
                                        </Descriptions>
                                    </div>
                                    <div>
                                        <List
                                            dataSource={invoice.invoiceLines}
                                            renderItem={(item: IInvoiceItem) => (
                                                <List.Item
                                                    actions={[
                                                        <Typography.Text key={item.name}>&euro; {item.subtotal}</Typography.Text>,
                                                    ]}
                                                >
                                                    <List.Item.Meta
                                                        title={item.name}
                                                        description={`Aantal: ${item.quantity}`}
                                                    />
                                                </List.Item>
                                            )}
                                            footer={(
                                                <div style={{ display: 'inline-block', width: '100%' }}>
                                                    <table style={{ textAlign: 'right', float: 'right' }}>
                                                        {this.renderPaymentDetail('Subtotaal', invoice!.amountTaxExclusive)}
                                                        {this.renderPaymentDetail('Korting', invoice!.discount)}
                                                        {this.renderPaymentDetail('Verzendkosten', invoice!.shipping)}
                                                        {this.renderPaymentDetail('BTW', invoice!.amountTax)}
                                                        {this.renderPaymentDetail('Totaal', invoice!.amountTaxInclusive)}
                                                        <tr>
                                                            <td><strong>Te betalen</strong></td>
                                                            <td style={{ paddingLeft: 24 }}>
                                                                <strong>&euro; {invoice!.amountDue}</strong>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="card-footer">
                                        <Button type="primary" onClick={this.pay}>Betaal</Button>
                                    </div>
                                </>
                            ) : (
                                <Result
                                    status="success"
                                    title={invoice && `Factuur ${invoice.paymentStatus === PaymentStatus.PAID ? 'Betaald' : 'Afgesloten'}`}
                                    subTitle={invoice && `Uw factuur ${invoice.id} werd reeds ${invoice.paymentStatus === PaymentStatus.PAID ? 'betaald' : 'afgesloten'}.`}
                                />
                            )}
                        </Card>
                    </Col>
                </Row>
            </Layout>
        );
    }

    private renderPaymentDetail = (label: string, value: string | number) => {
        if (value !== 0) {
            return (
                <tr>
                    <td>{label}</td>
                    <td style={{ paddingLeft: 24 }}>&euro; {value}</td>
                </tr>
            );
        }
        return;
    };

    private pay = () => {
        this.props.checkoutStore!.pay();
    };

   /*  private payVAT = () => {
        this.props.checkoutStore!.payVAT();
    }; */
}
