import { CheckoutPage } from './pages/checkout.page';
import { ResultPage } from './pages/result.page';

export const checkoutRoutesConfig = {
    checkout: {
        component: CheckoutPage,
        exact: true,
        path: '/',
    },
    result: {
        component: ResultPage,
        exact: true,
        path: '/result',
    },
};
